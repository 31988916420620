::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  height: 7px;
}
::-webkit-scrollbar-track {
  background: rgba(228, 221, 221, 0.562); /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: #6e23cf; /* color of the scroll thumb */
  border-radius: 15px;
}

.no-scrollbar {
  overflow-y: auto;
  scrollbar-width: none;
}

body {
  font-family: "DM Sans", serif;
}

.colored-placeholder {
  &::placeholder {
    color: red;
  }
}

.card-shadow {
  box-shadow: 0px 0px 85px -8px #dfdddd;
}

.mantine-Pagination-control[data-active="true"] {
  background-color: transparent;
  color: black;
  font-weight: 700;
  border: none;
  font-family: DM Sans;
  text-decoration: underline;
  text-decoration-color: #6e23cf;
  border-radius: 15px;

  :hover {
    background-color: transparent;
  }
}
.mantine-Pagination-control:not([data-active="true"]) {
  background-color: transparent;
  border: none;
  font-family: DM Sans;
  border-radius: 15px;
}
.send-button-container {
  position: relative;
  display: inline-block;
}

.custom-loader {
  position: absolute;
  width: 15px;
  height: 15px;
  margin-left: 40px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}
